html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
}

.image-container {
  flex: 1 0 20%; /* 5 images per row on larger screens */
  margin: 10px;
  cursor: pointer;
  max-width: calc(20% - 20px); /* Adjust based on flex-basis */
}

.img-fluid {
  width: 100%;
  height: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px;
  list-style: none;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 50px; /* Soft round edges */
  text-decoration: none;
  color: #e6e0e0;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
}

.modal-content {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modal-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.btn-close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Adjustments for different screen sizes */
@media (max-width: 1024px) {
  .image-container {
      flex: 1 0 30%; /* 3 images per row on medium screens */
      max-width: calc(30% - 20px); /* Adjust based on flex-basis */
  }
}

@media (max-width: 768px) {
  .image-container {
      flex: 1 0 45%; /* 2 images per row on smaller screens */
      max-width: calc(45% - 20px); /* Adjust based on flex-basis */
  }
}

@media (max-width: 480px) {
  .image-container {
      flex: 1 0 100%; /* 1 image per row on very small screens */
      max-width: calc(100% - 20px); /* Adjust based on flex-basis */
  }
}