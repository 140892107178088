/* src/App.css */
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

body {
  font-family: 'PT Sans', 'Gill Sans', "Gill Sans MT", 'Calibri', "Trebuchet MS",
  sans-serif;
}


.navbar {
  background-color: #000;
  border-bottom: 4px solid #74081f;
}

.nav-link {
  color: #ffffff;
  margin: 0 10px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
}


.nav-link.active {
  font-weight: bold;
  text-decoration: solid;
  color: #ffffff;
  
  
}

.nav-link:hover {
  color: #cccccc;
}

.navbar-brand {
  font-weight: bold;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
}

.image-container {
  flex: 1 1 calc(33.333% - 20px);
  margin: 10px;
  cursor: pointer;
  box-sizing: border-box;
}

.img-fluid {
  width: 100%;
  height: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px;
  list-style: none;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 50px;
  text-decoration: none;
  color: #74081f;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.page-link:hover {
  background-color: #74081f;
  border-color: #ff0000;
  color: #fff;
}

.page-item.active .page-link {
  background-color: #74081f;
  border-color: #74081f;
  color: #fff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
}

.modal-content {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modal-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.btn-close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}
.footer {
  background-color: #000000;
  color: #ffffff;
  padding: 20px 0;
  border-top: 2px solid #74081f;
}